/* General */

body {
  background-color: black;
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  transition: background-color 0.3s, color 0.3s;
}

h1 {
  font-size: 24px;
  margin: 0;
  color: rgb(144, 178, 183);
}

h2 {
  font-size: 16px;
  margin: auto 0;
  color: rgb(144, 178, 183, 0.4);
}

p {
  font-size: 18px;
}

@media (max-width: 600px) {
  p {
    font-size: 16px;
  }
}

.mode-switcher {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

body.day-mode {
  background-color: white;
  color: black;
}

/* Song pages */

.chords-and-lyrics {
  margin-left: 20px;
  font-weight: bold;
  opacity: 0.4;
  padding: 4px;
  margin: 4px;
}

a {
  color: rgb(144, 178, 183);
  text-decoration: none;
}

.controls {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  padding: 24px;
}

button {
  background-color: rgb(57 71 73);
  color: black;
  border: none;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  z-index: 999;
  display: inline-block;
  margin: 12px;
}

body.day-mode .controls {
  background-color: rgba(255, 255, 255, 0.5);
}

body.day-mode button {
  background-color: #d2e0e2;
  color: white;
}

.text-container {
  max-width: fit-content;
  margin: 0 auto;
  padding: 96px 0;
}

.chords {
  color: rgb(144, 178, 183);
  opacity: 0.8;
}

.verse-chorus-title {
  color: rgb(144, 178, 183);
  opacity: 0.4;
  margin-top: 48px;
  margin-bottom: 24px;
}

.home-button-container {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

.home-button {
  color: black;
  background-color: rgb(57 71 73);
  border: none;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 4px 4px 28px rgba(0, 0, 0, 1);
}

body.day-mode .home-button {
  background-color: #d2e0e2;
  color: white;
  box-shadow: 4px 4px 28px rgb(255, 255, 255);
}

.hint {
  font-size: 12px;
  color: rgb(144, 178, 183);
  opacity: 0.4;
  margin-top: 10px;
  display: block; /* Ensure hint is displayed as a block element */
  width: 100%; /* Take up full width */
}

.hint a {
  text-decoration: underline;
}

@media (min-width: 600px) {
  .song-meta-section {
    width: 100%;
  }
}

.font-size-controls {
  display: flex;
  width: 100%;
  z-index: 999;
}

.font-size-controls button {
  background-color: rgb(144, 178, 183, 0.4);
  margin-left: 24px;
  margin-right: 0;
  width: 50%;
  padding: 5px;
  border-radius: 12px;
  width: 40px;
}

body.day-mode .font-size-controls button {
  background-color: rgb(144, 178, 183, 0.4);
}

/* Start page */

.song-list {
  max-width: fit-content;
  margin: 96px auto;
  text-align: center;
}

.song-list ul {
  list-style-type: none;
  padding: 0;
}

.song-list li {
  margin: 10px 0;
}

.song-list a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
}

body.day-mode .song-list a {
  color: black;
}

.logo {
  display: block;
  margin: 48px auto;
  width: 100%;
  max-width: 120px;
  height: auto;
  border-radius: 48px;
  box-shadow: 0 12px 36px rgb(144, 178, 183);
}

.request-button {
  border-color: rgb(144, 178, 183, 0.4);
  background: none;
  color: rgb(57 71 73);
  border: dotted;
  border-radius: 12px;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
}

.request-button:hover {
  border-color: rgb(144, 178, 183);
}

body.day-mode .request-button {
  border-color: rgb(144, 178, 183, 0.4);
  background: none;
  color: rgb(144, 178, 183, 0.4);
}

body.day-mode .request-button:hover {
  border-color: rgb(144, 178, 183);
}

/* Chord images */

.chord-section {
  display: flex;
  /* flex-wrap: wrap;
  max-width: 600px;
  gap: 10px;
  margin: 40px 0; */
  flex-direction: column;
}

.chord-section button {
  padding: 6px 12px;
  margin-left: 0;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  background-color: rgb(144, 178, 183, 0.4);
  height: 48px;
}

.chords-listed {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  gap: 10px;
}

.chord,
.chord-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 4px 0;
  padding: 10px;
  border: 1px solid rgb(144, 178, 183, 0.5);
  border-radius: 12px;
}

.chord img {
  width: 60px;
  height: auto;
  margin-bottom: 4px;
  filter: invert(1);
}

body.day-mode .chord img {
  filter: invert(0);
}

.chord-text span {
  font-weight: bold;
  margin-bottom: 5px;
}

.chord-text pre {
  font-family: monospace;
  margin: 0;
}

/* Sharing functionality */

.share-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 80%;
  opacity: 0.4;
}

.song-url {
  font-size: 12px;
  color: rgb(144, 178, 183);
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(144, 178, 183);
  border-radius: 12px;
  padding: 5px;
  width: 250px;
  cursor: pointer;
}

body.day-mode .song-url {
  color: rgb(144, 178, 183);
  background-color: white;
  border: 1px solid rgb(144, 178, 183);
}

.share-button {
  background-color: rgb(144, 178, 183);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
}

.share-button:hover {
  background-color: #333;
}

body.day-mode .share-button {
  background-color: rgb(144, 178, 183);
  color: white;
}

body.day-mode .share-button:hover {
  background-color: #555;
}

@media (max-width: 600px) {
  .song-url {
    width: 100%;
  }
}
